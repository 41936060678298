(function ()
{
    let MarkerEditor = {
        mData: null,
        mSaveTimeout: null,
        mSceneIsLoaded: false,
        mNewId: 0,

        mStatusElement: null,
        mLoaderElement: null,
        mEditorElement: null,
        mViewportElement: null,
        mToolboxPropertiesBody: null,

        mSelectedObject: null,
        mEventTarget: new EventTarget(),

        mTools: {
            undo: null,
            redo: null,
            move: null,
            rotate: null,
            scale: null,
            delete: null,
            fullscreen: null,
            saveAndBack: null,
        },

        mDebugPhrase: "xyzzy",
        mDebugBuffer: "",

        mMapsIsLoading: false,
        mMapsLoadingPromise: null,

        init: function (id)
        {
            window.addEventListener("keyup", this.listenForDebug.bind(this));

            // Init toolbox
            let elem = document.querySelector('.collapsible.expandable');
            let instance = M.Collapsible.init(elem, {
                accordion: false
            });

            this.mData = {};
            this.mStatusElement = document.getElementById('toolbar-status');
            this.mEditorElement = document.getElementById('editor');
            this.mLoaderElement = document.getElementById('loader');
            this.mViewportElement = document.getElementById('viewport');
            this.mToolboxPropertiesBody = document.getElementById('editor-toolbox-properties-body');

            // Setup tools
            this.mTools.undo = document.getElementById('toolbar-undo');
            this.mTools.redo = document.getElementById('toolbar-redo');
            this.mTools.move = document.getElementById('toolbar-move');
            this.mTools.rotate = document.getElementById('toolbar-rotate');
            this.mTools.scale = document.getElementById('toolbar-scale');
            this.mTools.delete = document.getElementById('toolbar-delete');
            this.mTools.fullscreen = document.getElementById('toolbar-fullscreen');

            this.mTools.saveAndBack = document.getElementById('toolbar-save-and-back');

            this.mTools.saveAndBack.addEventListener('click', this.saveAndBack.bind(this));

            this.mTools.undo.addEventListener("click", function () { window.editor.undo(); });
            this.mTools.redo.addEventListener("click", function () { window.editor.redo(); });

            this.mTools.move.addEventListener("click", function () { window.editor.signals.transformModeChanged.dispatch("translate"); });
            this.mTools.rotate.addEventListener("click", function () { window.editor.signals.transformModeChanged.dispatch("rotate"); });
            this.mTools.scale.addEventListener("click", function () { window.editor.signals.transformModeChanged.dispatch("scale"); });

            this.mTools.delete.addEventListener("click", function ()
            {
                const object = window.editor.selected;

                if (object !== null && object.parent !== null)
                {
                    window.editor.execute(new RemoveObjectCommand(window.editor, object));
                }
            });

            this.mTools.fullscreen.addEventListener("click", this.onHandleFullscreen.bind(this));

            // Setup events
            let editorSignals = window.editor.signals;
            editorSignals.geometryChanged.add(this.scheduleSave.bind(this));
            editorSignals.objectAdded.add(this.scheduleSave.bind(this));
            editorSignals.objectChanged.add(this.scheduleSave.bind(this));

            editorSignals.objectRemoved.add(this.scheduleSave.bind(this));
            editorSignals.objectRemoved.add(this.onEditorItemDeleted.bind(this));

            editorSignals.materialChanged.add(this.scheduleSave.bind(this));
            editorSignals.sceneGraphChanged.add(this.scheduleSave.bind(this));
            editorSignals.scriptChanged.add(this.scheduleSave.bind(this));
            editorSignals.historyChanged.add(this.scheduleSave.bind(this));

            editorSignals.objectSelected.add(this.onObjectSelected.bind(this));
            editorSignals.objectChanged.add(this.onObjectChanged.bind(this));
            editorSignals.historyChanged.add(this.onHistoryChanged.bind(this));
            editorSignals.transformModeChanged.add(this.onTransformModeChanged.bind(this));

            window.document.addEventListener("fullscreenchange", this.onFullscreenChanged.bind(this));

            // Drag and drop
            let assetItems = document.querySelectorAll('.scanarius-asset');
            for (let i = 0; i < assetItems.length; i++)
            {
                assetItems[i].addEventListener("dragstart", this.onAssetDragStart.bind(this), false);
            }
            this.mViewportElement.addEventListener("dragover", this.onEditorDragover.bind(this), false);
            this.mViewportElement.addEventListener("drop", this.onEditorDrop.bind(this), false);


            // Load scene
            this.load(id);
        },

        onAssetDragStart: function (event)
        {
            // get id from data-asset-id attribute
            let id = event.target.getAttribute("data-asset-id");
            if (id)
            {
                event.dataTransfer.setData("text/x-scanarius-asset-id", id);
                event.dataTransfer.dropEffect = "copy";

                // find child img element
                let img = event.target.querySelector("img");
                if (img)
                {
                    event.dataTransfer.setDragImage(img, 0, 0);
                }
            }
        },

        onEditorDrop: function (event)
        {
            event.preventDefault();
            event.stopPropagation();

            let id = event.dataTransfer.getData("text/x-scanarius-asset-id");
            if (id)
            {
                this.dropAsset(id);
            }
        },
        onEditorDragover: function (event)
        {
            event.preventDefault();
            if (event.dataTransfer.types.length > 0)
            {
                if (event.dataTransfer.types[0] === "text/x-scanarius-asset-id")
                {
                    event.dataTransfer.dropEffect = "copy";
                    return;
                }
            }

            event.dataTransfer.dropEffect = "none";
        },

        dropAsset: function (asset_id)
        {
            if (this.mData.assets[asset_id])
            {
                this.load_asset(this.mData.assets[asset_id], null);
            }
            else
            {
                // load asset data from api
                this.api_get("assets/get", { id: asset_id })
                    .then(((function (response)
                    {
                        if (response.success)
                        {
                            this.mData.assets[asset_id] = response.asset;
                            this.dropAsset(asset_id);
                        }
                        else
                        {
                            this.handleError(response.error);
                        }
                    }).bind(this)));
            }
        },

        saveAndBack: function ()
        {
            let $this = MarkerEditor;
            this.save().then(function ()
            {
                let returnUrl = document.location.origin + document.location.pathname + "?view=project&id=" + $this.mData.marker.bundle_uid;
                document.location.assign(returnUrl);
            });
        },

        load: async function (id)
        {
            this.mStatusElement.innerHTML = "Lade...";

            this.mData = null;
            //this.clearMapMarker();

            this.setupBaseScene();

            await this.loadMarkerData(id);

            let loadingPromises = [];

            if (this.mData != null)
            {
                for (let i = 0; i < this.mData.items.length; i++)
                {
                    let item = this.mData.items[i];
                    loadingPromises.push(this.load_item(item));
                }

                await Promise.all(loadingPromises);
            }
            else
            {
                this.handleError("Marker konnte nicht geladen werden.");
                this.mStatusElement.innerHTML = "Fehler beim Laden";
            }

            window.editor.signals.windowResize.dispatch();
            window.editor.history.clear();
            window.editor.select(null);
            this.mStatusElement.innerHTML = "Geladen";
            this.mSceneIsLoaded = true;
            this.mLoaderElement.classList.remove('active');
        },

        load_item: async function (item)
        {
            return new Promise((resolve, reject) =>
            {
                let asset = this.mData.assets[item.asset_uid];

                if (asset == null)
                {
                    this.handleError("Mediendatei " + item.asset_uid + " konnte nicht geladen werden.");
                }
                else
                {
                    this.load_asset(asset, item).then((function ()
                    {
                        resolve();
                    })).catch((function (error)
                    {
                        this.handleError(error);
                        reject(error);
                    }).bind(this));
                }
            });
        },

        load_asset: async function (asset, item)
        {
            return new Promise((resolve, reject) =>
            {
                if (asset.type == "3d")
                {
                    let folderPath = this.getAssetUrl(asset) + "/";
                    let materialLoader = new MTLLoader();
                    materialLoader.setPath(folderPath);

                    this.api_get("assets/get/folder", { id: asset.uid }).then((function (data)
                    {
                        if (data.success)
                        {
                            let folder = data.folder;

                            let findInFolder = function (root, needle, stem)
                            {
                                if (stem === undefined) stem = "";

                                for (var index in root)
                                {
                                    var branch = root[index];
                                    if (!branch.is_dir)
                                    {
                                        if (needle.indexOf("|") != -1)
                                        {
                                            let parts = needle.split("|");
                                            for (let i = 0; i < parts.length; i++)
                                            {
                                                if (branch.name.toLowerCase().endsWith(parts[i]))
                                                {
                                                    return stem + branch.name;
                                                }
                                            }
                                        }
                                        else
                                        {
                                            if (branch.name.toLowerCase().endsWith(needle))
                                            {
                                                return stem + branch.name;
                                            }
                                        }
                                    }
                                    else if (branch.is_dir)
                                    {
                                        var subfind = findInFolder(branch.files, needle, stem + branch.name + "/");
                                        if (subfind)
                                            return subfind;
                                    }
                                }

                                return null;
                            };

                            let mtlFile = findInFolder(folder, ".mtl");
                            let objFile = findInFolder(folder, ".obj");
                            let textureFile = findInFolder(folder, ".png|.jpeg|.jpg|.gif|.bmp|.tga");

                            if (textureFile)
                            {
                                // get folder from texture file
                                let textureFolder = textureFile.split("/").splice(0, textureFile.split("/").length - 1).join("/");
                                materialLoader.setResourcePath([folderPath, textureFolder].join("/") + "/");
                            }

                            materialLoader.load(mtlFile, (function (materials)
                            {
                                // materials.preload();

                                let objLoader = new OBJLoader();
                                console.log(materials);
                                objLoader.setMaterials(materials);
                                objLoader.setPath(folderPath);
                                objLoader.load(objFile, (function (mesh)
                                {
                                    // Adjust scale
                                    let box = (new THREE.Box3).setFromObject(mesh);
                                    let size = box.max.sub(box.min).toArray();
                                    let biggest = Math.max.apply(null, size);
                                    size = 600 / biggest;
                                    let matrix = (new THREE.Matrix4).compose(
                                        new THREE.Vector3(0, 0, 0),
                                        (new THREE.Quaternion).setFromEuler(new THREE.Euler(-3 * Math.PI / 2, 0, 0, "XYZ")),
                                        new THREE.Vector3(size, size, size)
                                    );
                                    mesh.children.forEach(function (mesh)
                                    {
                                        mesh.applyMatrix(matrix);
                                    });

                                    this.add_item_to_scene(mesh, asset, item);

                                    resolve();
                                }).bind(this), function (xhr)
                                {
                                    // console.log((xhr.loaded / xhr.total * 100) + "% loaded");
                                });
                            }).bind(this));
                        }
                        else
                        {
                            reject("Folder not loaded");
                        }
                    }).bind(this));
                }
                else
                {
                    let url = "";
                    if (["button", "2d"].indexOf(asset.type) >= 0 || (item && item.type == "reference"))
                    {
                        url = this.getAssetUrl(asset);
                    }
                    else
                    {
                        url = this.getAssetThumbnailUrl(asset);
                    }

                    this.promised_texture_load(url).then((function (texture)
                    {
                        texture.needsUpdate = true;
                        texture.wrapS = THREE.RepeatWrapping;
                        texture.wrapT = THREE.RepeatWrapping;

                        let material = new THREE.MeshBasicMaterial({
                            map: texture,
                            side: THREE.DoubleSide,
                            transparent: true
                        });

                        let width = texture.image.width,
                            height = texture.image.height,
                            ratio = 600 / width;

                        let size = {
                            width: asset.type == "image" ? width * ratio : width,
                            height: asset.type == "image" ? height * ratio : height
                        };

                        let geometry = new THREE.PlaneGeometry(size.width, size.height);
                        let mesh = new THREE.Mesh(geometry, material);

                        this.add_item_to_scene(mesh, asset, item);

                        resolve();
                    }).bind(this)).catch((function (error)
                    {
                        this.handleError(error);
                        reject(error);
                    }).bind(this));
                }
            });
        },

        promised_texture_load: function (url)
        {
            return new Promise(function (resolve, reject)
            {
                let textureLoader = new THREE.TextureLoader();
                textureLoader.load(url, resolve, null, reject);
            });
        },

        add_item_to_scene: function (mesh, asset, item)
        {
            mesh.name = asset.name;
            if (!item)
            {
                mesh.uid = "_NEW:" + this.mNewId++;

                mesh.asset_uid = asset.uid;
                mesh.is_item = true;
                mesh.position.x = 0;
                mesh.position.y = 0;
                mesh.position.z = 1;

                window.editor.addObject(mesh);
            }
            else
            {
                if (item.type == "reference")
                {
                    mesh.position.z = -1; // one BELOW the ground so the user can see the grid
                    window.editor.scene.add(mesh);
                }
                else if (item.type == "overlay")
                {
                    mesh.uid = item.uid;
                    mesh.asset_uid = asset.uid;
                    mesh.is_item = true;

                    mesh.position.x = item.positionX * 600;
                    mesh.position.y = item.positionY * 600;
                    mesh.position.z = item.positionZ * 600;

                    mesh.rotation.x = item.rotationX;
                    mesh.rotation.y = item.rotationY;
                    mesh.rotation.z = item.rotationZ;

                    mesh.scale.x = item.scaleX;
                    mesh.scale.y = item.scaleY;
                    mesh.scale.z = item.scaleZ;

                    try
                    {
                        if(typeof item.params == "string")
                            mesh.params = JSON.parse(item.params);
                        else
                            mesh.params = item.params;
                    }
                    catch (ex)
                    {
                        mesh.params = {};
                    }


                    window.editor.addObject(mesh);
                }
            }
        },

        setupBaseScene: function ()
        {
            window.editor.clear();

            // Set background color
            window.editor.signals.sceneBackgroundChanged.dispatch("Color", "#e0e0e0");

            // Add Ambient Light
            var color = 0xffffff;
            var light = new THREE.AmbientLight(color);
            light.intensity = 1.0;
            light.name = 'AmbientLight';

            window.editor.scene.add(light); // not selectable
            // window.editor.addObject(light); // selectable

            // Rotate scene
            window.editor.scene.rotation.x = -90 * THREE.Math.DEG2RAD;
        },

        loadMarkerData: async function (id)
        {
            let response = await this.api_get("marker/get", { id: id });

            if (response.success)
            {
                this.mData = {
                    marker: response.marker,
                    items: response.items,
                    assets: response.assets
                };
            }
            else
            {
                this.handleError("Fehler beim Auswerten der Daten: " + response.error);
            }
        },

        getBasepath: function ()
        {
            return window.location.href.substring(0, window.location.href.lastIndexOf('/'));
        },

        getAssetUrl: function (asset)
        {
            return [this.getBasepath(), "data", asset.customer_uid, this.mData.marker.bundle_uid, "assets", asset.filename].join("/");
        },
        getAssetThumbnailUrl: function (asset)
        {
            if (asset.type == "vimeo")
            {
                let result = /[^/]*$/.exec(asset.filename)[0];
                let vimeo_id = result.split(".")[0];

                return [this.getBasepath(), "data", asset.customer_uid, this.mData.marker.bundle_uid, "assets", vimeo_id + "_thumb.jpg"].join("/");
            }
            else
            {
                let file_name = asset.filename.lastIndexOf('.') < 0 ? asset.filename : asset.filename.substring(0, asset.filename.lastIndexOf('.'));
                return [this.getBasepath(), "data", asset.customer_uid, this.mData.marker.bundle_uid, "assets", file_name + "_thumb.jpg"].join("/");
            }
        },

        api_get: async function (cmd, payload = {})
        {
            return new Promise((resolve, reject) =>
            {
                let apiUrl = window.location.href.substring(0, window.location.href.lastIndexOf('/') + 1) + "api/index.php";

                let body = new FormData();
                body.append("cmd", cmd);

                for (let key in payload)
                {
                    body.append("payload[" + key + "]", payload[key]);
                }

                fetch(apiUrl, {
                    method: "POST",
                    body: body
                }).then(response =>
                {
                    if (response.ok)
                    {
                        resolve(response.json());
                    }
                    else
                    {
                        reject(response.statusText);
                    }
                }).catch(error =>
                {
                    reject(error);
                });
            });
        },

        onHandleFullscreen: function ()
        {
            if (document.fullscreenElement)
            {
                document.exitFullscreen();
            }
            else
            {
                this.mEditorElement.requestFullscreen();
            }
        },
        onFullscreenChanged: function (event)
        {
            if (document.fullscreenElement)
            {
                this.mEditorElement.classList.add("fullscreen");
                this.mTools.fullscreen.classList.add("blue-text");
            }
            else
            {
                this.mEditorElement.classList.remove("fullscreen");
                this.mTools.fullscreen.classList.remove("blue-text");
            }

            window.editor.signals.windowResize.dispatch();
        },
        onObjectSelected: function (object)
        {
            if (this.mData && object)
            {
                this.mSelectedObject = object;
                // Make new event for selected object
                let event = new CustomEvent("objectChanged", { detail: { object: object } });

                this.makeToolbox();
                this.mTools.move.classList.remove('disabled');
                this.mTools.rotate.classList.remove('disabled');
                this.mTools.scale.classList.remove('disabled');
                this.mTools.delete.classList.remove('disabled');
            }
            else
            {
                this.mSelectedObject = null;
                this.populateToolbox("unselected", true);
                this.mTools.move.classList.add('disabled');
                this.mTools.rotate.classList.add('disabled');
                this.mTools.scale.classList.add('disabled');
                this.mTools.delete.classList.add('disabled');
            }
        },
        onObjectChanged: function (object)
        {
            if (object == this.mSelectedObject)
            {
                this.onSelectedObjectChanged(object);
            }
        },
        onSelectedObjectChanged: function (object)
        {
            this.mEventTarget.dispatchEvent(new Event("changed"));
        },
        onTransformModeChanged: function (mode)
        {
            this.mTools.move.classList.remove('blue-text');
            this.mTools.rotate.classList.remove('blue-text');
            this.mTools.scale.classList.remove('blue-text');

            if (mode == "translate")
            {
                this.mTools.move.classList.add('blue-text');
            }
            else if (mode == "rotate")
            {
                this.mTools.rotate.classList.add('blue-text');
            }
            else if (mode == "scale")
            {
                this.mTools.scale.classList.add('blue-text');
            }

        },
        onHistoryChanged: function ()
        {
            if (window.editor.history.undos.length > 0)
            {
                this.mTools.undo.classList.remove('disabled');
            }
            else
            {
                this.mTools.undo.classList.add('disabled');
            }

            if (window.editor.history.redos.length > 0)
            {
                this.mTools.redo.classList.remove('disabled');
            }
            else
            {
                this.mTools.redo.classList.add('disabled');
            }
        },

        onEditorItemDeleted: function (object)
        {
            if (this.mSceneIsLoaded)
            {
                if (object.is_item)
                {
                    let index = -1;
                    for (let i = 0; i < this.mData.items.length; i++)
                    {
                        if (this.mData.items[i].uid == object.uid)
                        {
                            index = i;
                            break;
                        }
                    }

                    if (index >= 0)
                    {
                        this.mData.items.splice(index, 1);
                    }
                }
            }
        },

        scheduleSave: function ()
        {
            if (!this.mData || !this.mSceneIsLoaded)
                return;

            window.onbeforeunload = function ()
            {
                return 'Sie haben ungespeicherte Änderungen.';
            };

            this.mStatusElement.innerHTML = 'Ungespeicherte Änderungen';

            if (this.mSaveTimeout)
                clearTimeout(this.mSaveTimeout);

            this.mSaveTimeout = setTimeout(this.save.bind(this), 1000);
        },

        save: function ()
        {
            return new Promise((resolve, reject) =>
            {
                let $this = MarkerEditor;
                let items = $this.updateItemsFromEditor();
                if (items != null)
                {
                    $this.mStatusElement.innerHTML = 'Speichern...';

                    $this.api_get("marker/save", {
                        id: $this.mData.marker.uid,
                        items: JSON.stringify(items)
                    }).then((function (response)
                    {
                        if (response.success)
                        {
                            if (response.ids)
                            {
                                $this.setIdsFromAPIForNewItems(response.ids);
                            }

                            $this.mStatusElement.innerHTML = 'Gespeichert';
                            window.onbeforeunload = null;
                            resolve();
                        }
                        else
                        {
                            console.error(error);
                            $this.handleError("Fehler beim Speichern: " + response.error);
                            $this.mStatusElement.innerHTML = 'Fehler beim Speichern';
                            reject();
                        }
                    }).bind($this))
                        .catch((function (error)
                        {
                            console.error(error);
                            $this.handleError("Fehler beim Speichern der Daten: " + error);
                            $this.mStatusElement.innerHTML = 'Fehler beim Speichern';
                            reject();
                        }).bind($this));
                }
                else
                {
                    console.error("Could not update items from editor");
                    reject();
                }
            });
        },

        setIdsFromAPIForNewItems: function (items)
        {
            for (let i = 0; i < items.length; i++)
            {
                let item = items[i];

                // Update in data
                for (let j = 0; j < this.mData.items.length; j++)
                {
                    if (this.mData.items[j].uid == item.old)
                    {
                        this.mData.items[j].uid = item.new;
                        break;
                    }
                }

                // Update in editor
                for (let j = 0; j < window.editor.scene.children.length; j++)
                {
                    if (window.editor.scene.children[j].uid == item.old)
                    {
                        window.editor.scene.children[j].uid = item.new;
                        break;
                    }
                }
            }
        },

        updateItemsFromEditor: function ()
        {
            let referenceItem = null;
            for (let i = 0; i < this.mData.items.length; i++)
            {
                if (this.mData.items[i].type == "reference")
                {
                    referenceItem = this.mData.items[i];
                }
            }

            if (referenceItem == null)
            {
                this.handleError("Marker hat keine Referenz");
                return null;
            }

            for (let i = 0; i < window.editor.scene.children.length; i++)
            {
                let sceneObject = window.editor.scene.children[i];
                let index = -1;

                for (let j = 0; j < this.mData.items.length; j++)
                {
                    if (this.mData.items[j].uid == sceneObject.uid)
                    {
                        index = j;
                        break;
                    }
                }

                if (sceneObject.is_item)
                {
                    // Transform to CraftAR Coordinates
                    let transformedPosition = sceneObject.position.clone().divideScalar(600);
                    let transformedMatrix = new THREE.Matrix4().compose(
                        new THREE.Vector3(0, 0, 0),
                        sceneObject.quaternion,
                        new THREE.Vector3(1, 1, 1)
                    );

                    let item = {
                        uid: sceneObject.uid,
                        asset_uid: sceneObject.asset_uid,
                        type: "overlay",
                        params: sceneObject.params == null ? null : JSON.stringify(sceneObject.params),

                        matrix: JSON.stringify(transformedMatrix.elements),

                        positionX: transformedPosition.x,
                        positionY: transformedPosition.y,
                        positionZ: transformedPosition.z,

                        rotationX: sceneObject.rotation.x,
                        rotationY: sceneObject.rotation.y,
                        rotationZ: sceneObject.rotation.z,

                        scaleX: sceneObject.scale.x,
                        scaleY: sceneObject.scale.y,
                        scaleZ: sceneObject.scale.z
                    };

                    if (index >= 0)
                    {
                        this.mData.items[index] = item;
                    }
                    else
                    {
                        this.mData.items.push(item);
                    }
                }
            }

            return this.mData.items;
        },

        handleError: function (error)
        {

        },

        listenForDebug: function (event)
        {
            this.mDebugBuffer += event.key;
            if (this.mDebugBuffer == this.mDebugPhrase)
            {
                this.mDebugBuffer = "";
                document.body.classList.toggle('debug');
            }
            else
            {
                if (this.mDebugPhrase.indexOf(this.mDebugBuffer) != 0)
                    this.mDebugBuffer = "";
            }
        },


        getValueOfSelectedObjectForPropertyPath: function (propertyPath)
        {
            let object = this.mSelectedObject;
            if (object != null)
            {
                let properties = propertyPath.split('.');
                for (let i = 0; i < properties.length; i++)
                {
                    if (object == null || object == undefined)
                        return null;

                    object = object[properties[i]];
                }

                return object !== undefined ? object : "";
            }
            else
            {
                return null;
            }
        },

        setValueOfSelectedObjectForPropertyPath: function (propertyPath, value)
        {
            let object = this.mSelectedObject;
            if (object != null)
            {
                let properties = propertyPath.split('.');
                for (let i = 0; i < properties.length - 1; i++)
                {
                    if (object == null || object == undefined)
                        object = {};

                    if (object[properties[i]] == undefined)
                        object[properties[i]] = {};

                    object = object[properties[i]];
                }

                object[properties[properties.length - 1]] = value;
            }
        },

        bindInputElementToSelectedObjectProperty: function (element, propertyPath, propertyType, setConversion = null, getConversion = null)
        {
            if (element)
            {
                let updateValueInField = (function ()
                {
                    let value = this.getValueOfSelectedObjectForPropertyPath(propertyPath);
                    if (getConversion != null && typeof getConversion == "function")
                    {
                        value = getConversion(value);
                    }

                    if (element.type == "checkbox")
                    {
                        element.checked = value === true;
                    }
                    else
                    {
                        element.value = value;
                    }
                }).bind(this);
                let onChange = (function (event)
                {
                    let castValue = event.target.value;
                    if (element.type == "checkbox")
                    {
                        castValue = event.target.checked;
                    }

                    if (propertyType == "float")
                    {
                        castValue = parseFloat(castValue);
                    }
                    else if (propertyType == "int")
                    {
                        castValue = parseInt(castValue);
                    }
                    else if (propertyType == "bool" || propertyType == "boolean")
                    {
                        if (castValue !== true && castValue !== false)
                        {
                            castValue = (castValue == "true") || (castValue == "1");
                        }
                    }

                    if (setConversion != null && typeof setConversion == "function")
                    {
                        castValue = setConversion(castValue);
                    }

                    this.setValueOfSelectedObjectForPropertyPath(propertyPath, castValue);

                    window.editor.signals.objectChanged.dispatch(this.mSelectedObject);
                }).bind(this);

                updateValueInField();
                element.onkeydown = function (ev) { ev.stopPropagation(); };
                element.onkeyup = function (ev) { ev.stopPropagation(); };
                element.onchange = onChange;
                element.oninput = onChange;

                // if checkbox
                if (element.type == "checkbox")
                {
                    element.onclick = onChange;
                }


                this.mEventTarget.addEventListener("changed", (function (e)
                {
                    updateValueInField();
                }).bind(this));
            }
            else
            {
                console.warn("bindInputElementToSelectedObjectProperty: element is null", propertyPath);
            }
        },

        makeToolbox: function ()
        {
            let e = null;
            if (this.mSelectedObject && this.mSelectedObject.is_item)
            {
                let e = this.mToolboxPropertiesBody;
                let item = this.mData.items.find(function (i) { return i.uid == this.mSelectedObject.uid; }.bind(this));
                let asset = this.mData.assets[item.asset_uid];

                this.populateToolbox("common", true);
                this.populateToolbox(asset ? asset.type : null);

                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=position_x]"), "position.x", "float");
                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=position_y]"), "position.y", "float");
                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=position_z]"), "position.z", "float");

                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=rotation_x]"), "rotation.x", "float",
                    (function (value) { return THREE.MathUtils.degToRad(value); }).bind(this),
                    (function (value) { return THREE.MathUtils.radToDeg(value); }).bind(this));
                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=rotation_y]"), "rotation.y", "float",
                    (function (value) { return THREE.MathUtils.degToRad(value); }).bind(this),
                    (function (value) { return THREE.MathUtils.radToDeg(value); }).bind(this));
                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=rotation_z]"), "rotation.z", "float",
                    (function (value) { return THREE.MathUtils.degToRad(value); }).bind(this),
                    (function (value) { return THREE.MathUtils.radToDeg(value); }).bind(this));

                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=scale_x]"), "scale.x", "float");
                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=scale_y]"), "scale.y", "float");
                this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=scale_z]"), "scale.z", "float");

                if (this.mSelectedObject.params != null && typeof this.mSelectedObject.params == "string")
                {
                    try
                    {
                        this.mSelectedObject.params = JSON.parse(this.mSelectedObject.params);
                    }
                    catch (e)
                    {
                        this.mSelectedObject.params = {};
                    }
                }

                switch (asset.type)
                {
                    case "video":
                        this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=offline]"), "params.offline", "bool");
                        break;

                    case "button":
                        this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=action]"), "params.action", "string",
                            (function (value)
                            {
                                // Update toolbox to show different action options
                                setTimeout((function () { this.makeToolbox(); }).bind(this), 100);
                                return value;
                            }).bind(this));

                        if (this.mSelectedObject.params && this.mSelectedObject.params.action)
                        {
                            this.populateToolbox("button-" + this.mSelectedObject.params.action);

                            let httpsFixer = (function (value)
                            {
                                if (value == null)
                                    value = "";

                                // if url does not start with https://
                                if (value.indexOf("https://") != 0)
                                {
                                    value = "https://" + value;
                                }

                                return value;
                            }).bind(this);

                            switch (this.mSelectedObject.params.action)
                            {
                                case "link":
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=url]"), "params.url", "string",
                                        httpsFixer, httpsFixer);
                                    break;
                                case "facebook":
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=facebook_url]"), "params.facebook_url", "string",
                                        httpsFixer, httpsFixer);
                                    break;
                                case "twitter":
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=twitter_name]"), "params.twitter_name", "string");
                                    break;

                                case "email":
                                    let textareaGrow = (function (value)
                                    {
                                        if (value == null)
                                            value = "";

                                        if (e.querySelector("[name=text]"))
                                            setTimeout((function () { M.textareaAutoResize(e.querySelector("[name=text]")); }).bind(this), 100);

                                        return value;
                                    }).bind(this);
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=to]"), "params.to", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=subject]"), "params.subject", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=text]"), "params.text", "string",
                                        textareaGrow, textareaGrow);
                                    break;

                                case "contact":
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=contact_name]"), "params.contact_name", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=contact_email]"), "params.contact_email", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=contact_tel]"), "params.contact_tel", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=contact_street]"), "params.contact_street", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=contact_city]"), "params.contact_city", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=contact_zip]"), "params.contact_zip", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=contact_country]"), "params.contact_country", "string");
                                    break;

                                case "calendar":
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=calendar_name]"), "params.calendar_name", "string");
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=calendar_description]"), "params.calendar_description", "string");

                                    e.querySelector("[name=calendar_date]").value = (this.mSelectedObject.params && this.mSelectedObject.params.calendar_date) ? dayjs(this.mSelectedObject.params.calendar_date).format("DD.MM.YYYY HH:mm") : "";
                                    e.querySelector("[name=calendar_date_end]").value = (this.mSelectedObject.params && this.mSelectedObject.params.calendar_date_end) ? dayjs(this.mSelectedObject.params.calendar_date_end).format("DD.MM.YYYY HH:mm") : "";

                                    this.makeDateTimePicker(e.querySelector("[name=calendar_date]"), (function (value)
                                    {
                                        if (this.mSelectedObject.params == null)
                                            this.mSelectedObject.params = {};

                                        this.mSelectedObject.params.calendar_date = new Date(value).getTime();
                                        window.editor.signals.objectChanged.dispatch(this.mSelectedObject);
                                    }).bind(this));

                                    this.makeDateTimePicker(e.querySelector("[name=calendar_date_end]"), (function (value)
                                    {
                                        if (this.mSelectedObject.params == null)
                                            this.mSelectedObject.params = {};

                                        this.mSelectedObject.params.calendar_date_end = new Date(value).getTime();
                                        window.editor.signals.objectChanged.dispatch(this.mSelectedObject);
                                    }).bind(this));
                                    break;

                                case "maps":
                                    this.bindInputElementToSelectedObjectProperty(e.querySelector("[name=name]"), "params.name", "string");
                                    this.requireGoogleMaps().then((function ()
                                    {
                                        e.querySelector("#maps-loader").style.display = "none";
                                        let maps = new google.maps.Map(document.getElementById('editor-map'), {
                                            // 52.5162778,13.3755154 = brandenburger tor
                                            center: { lat: 52.5162778, lng: 13.3755154 },
                                            zoom: 16
                                        });
                                        let geocoder = new google.maps.Geocoder();
                                        let mapMarker = null;

                                        let updateMarker = (function (latLng)
                                        {
                                            if (this.mSelectedObject.params == null)
                                                this.mSelectedObject.params = {};

                                            this.mSelectedObject.params.lat = latLng.lat();
                                            this.mSelectedObject.params.lng = latLng.lng();
                                            window.editor.signals.objectChanged.dispatch(this.mSelectedObject);
                                        }).bind(this);

                                        let updateMapMarker = (function (latLng)
                                        {
                                            if (mapMarker == null)
                                            {
                                                mapMarker = new google.maps.Marker({
                                                    position: latLng,
                                                    map: maps,
                                                    draggable: true
                                                });

                                                mapMarker.addListener('dragend', (function (event)
                                                {
                                                    updateMarker(event.latLng);
                                                }).bind(this));
                                            }
                                            else
                                            {
                                                mapMarker.setPosition(latLng);
                                            }

                                            maps.setCenter(latLng);
                                            maps.setZoom(16);
                                        }).bind(this);

                                        if (this.mSelectedObject.params && this.mSelectedObject.params.lat && this.mSelectedObject.params.lng)
                                        {
                                            let latLng = new google.maps.LatLng(this.mSelectedObject.params.lat, this.mSelectedObject.params.lng);
                                            updateMapMarker(latLng);
                                        }
                                        else
                                        {
                                            let latLng = new google.maps.LatLng(52.5162778, 13.3755154);
                                            updateMapMarker(latLng);
                                            updateMarker(latLng);
                                        }

                                        let geocodeAddress = (function (search)
                                        {
                                            document.querySelector("#maps-loader").style.display = "block";
                                            geocoder.geocode({ 'address': search }, (function (results, status)
                                            {
                                                if (status === 'OK')
                                                {
                                                    updateMapMarker(results[0].geometry.location);
                                                    updateMarker(results[0].geometry.location);
                                                    
                                                    document.querySelector("#maps-loader").style.display = "none";
                                                }
                                                else
                                                {
                                                    document.querySelector("#maps-loader").style.display = "none";
                                                    console.log('Geocode was not successful for the following reason: ' + status);
                                                }
                                            }).bind(this));
                                        }).bind(this);

                                        e.querySelector("[name=geocode]").addEventListener("keydown", (function (e)
                                        {
                                            e.stopPropagation();

                                            if (e.keyCode == 13)
                                            {
                                                e.target.blur();
                                                geocodeAddress(e.target.value);
                                            }

                                        }).bind(this));
                                        e.querySelector("[name=geocode]").addEventListener("keyup", (function (e)
                                        {
                                            e.stopPropagation();
                                        }).bind(this));

                                    }).bind(this)).catch((function (error)
                                    {
                                        console.error(error);
                                    }).bind(this));

                                    break;
                            }
                        }
                        else
                        {
                            console.warn("No action selected", this.mSelectedObject.params);
                        }

                        break;
                }

                // Initialize spawned selects
                M.FormSelect.init(e.querySelectorAll("select"), {});

                // Details
                this.populateToolbox("details");
                let asset_name = asset.name.substring(0, asset.name.lastIndexOf("."));

                e.querySelector("[name=asset-name]").innerHTML = asset_name;
                e.querySelector("[name=asset-format]").innerHTML = asset.format;
                e.querySelector("[name=asset-resolution]").innerHTML = asset.dimensions + " (" + asset.aspect_ratio + ")";
                e.querySelector("[name=asset-size]").innerHTML = asset.readable_size;

            }

        },
        populateToolbox: function (template, clear = false)
        {
            let tpl = document.getElementById("template-toolbox-" + template);
            if (clear)
            {
                this.mToolboxPropertiesBody.innerHTML = "";
            }

            if (tpl)
            {
                for (let i = 0; i < tpl.children.length; i++)
                {
                    let child = tpl.children[i];
                    this.mToolboxPropertiesBody.appendChild(child.cloneNode(true));
                }
            }
            else
            {
                console.error("Template not found: " + template);
            }
        },

        makeDateTimePicker: function (element, onChange, datePickerOptions = {}, timePickerOptions = {})
        {
            if (element == null)
                throw new Error("Element is null");

            if (element.tagName != "INPUT")
                throw new Error("Element is not an input");

            if (element.type != "text")
                throw new Error("Element is not a text input");

            if (element.id == "")
                element.id = "date-time-picker-" + Math.random().toString(36).substring(7);

            let datePickerElement = document.createElement("input");
            datePickerElement.type = "text";
            datePickerElement.id = element.id + "-date";
            datePickerElement.style.display = "none";

            let timePickerElement = document.createElement("input");
            timePickerElement.type = "text";
            timePickerElement.id = element.id + "-time";
            timePickerElement.style.display = "none";

            let datepicker = null;
            let timepicker = null;

            let datePickerDefaultOptions = {
                format: "dd.mm.yyyy",
                defaultDate: new Date(),
                firstDay: 1,
                showClearBtn: false,
                i18n: {
                    cancel: "Abbrechen",
                    clear: "Löschen",
                    done: "OK",
                    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
                    monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
                    weekdays: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
                    weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                    weekdaysAbbrev: ["S", "M", "D", "M", "D", "F", "S"]
                },
                onSelect: function (date)
                {
                },
                onClose: function ()
                {
                    timepicker.open();
                }
            };
            datePickerDefaultOptions = Object.assign(datePickerDefaultOptions, datePickerOptions);

            let timePickerDefaultOptions = {
                showClearBtn: false,
                twelveHour: false,
                i18n: {
                    cancel: "Abbrechen",
                    clear: "Löschen",
                    done: "OK",
                },
                onCloseEnd: function ()
                {
                    element.value = datePickerElement.value + " " + timePickerElement.value;

                    let date = new Date(datepicker.date);
                    date.setHours(timepicker.time.split(":")[0]);
                    date.setMinutes(timepicker.time.split(":")[1]);
                    date.setSeconds(0);
                    date.setMilliseconds(0);

                    onChange(date);
                }
            };
            timePickerDefaultOptions = Object.assign(timePickerDefaultOptions, timePickerOptions);


            element.parentNode.insertBefore(datePickerElement, element);
            element.parentNode.insertBefore(timePickerElement, element);
            datepicker = M.Datepicker.init(datePickerElement, datePickerDefaultOptions);
            timepicker = M.Timepicker.init(timePickerElement, timePickerDefaultOptions);

            element.addEventListener("click", function (e)
            {
                e.stopPropagation();
                e.preventDefault();

                datepicker.open();
            });
        },

        requireGoogleMaps: function ()
        {
            let $this = this;

            return new Promise(function (resolve, reject)
            {
                if ($this.mMapsIsLoaded)
                {
                    resolve();
                    return;
                }

                if ($this.mMapsLoadingPromise)
                {
                    $this.mMapsLoadingPromise.then(function ()
                    {
                        resolve();
                    });
                    return;
                }

                $this.mMapsLoadingPromise = new Promise(function (resolve, reject)
                {
                    $this.mMapsLoadingPromise = null;

                    let script = document.createElement("script");
                    script.type = "text/javascript";
                    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYviPEKGPjve5PeKSLRZT0GQSkF-iiVBs&callback=onGoogleMapsLoaded";

                    window.onGoogleMapsLoaded = function ()
                    {
                        $this.mMapsIsLoaded = true;
                        resolve();
                    };

                    document.body.appendChild(script);
                }).then(function ()
                {
                    $this.mMapsLoadingPromise = null;
                    resolve();
                });
            });
        },
    };

    window.MarkerEditor = MarkerEditor;
})();